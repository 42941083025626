import Choices from "choices.js";

const customSelectList = document.querySelectorAll(".custom-select select");

customSelectList.forEach((selectEl) => {
    const instance = new Choices(selectEl, {
        searchEnabled: selectEl.dataset.search === "true",
        itemSelectText: "",
        allowHTML: true,
    });

    if (selectEl.matches(".custom-select__isp-periods")) {
        const wrapper = selectEl.closest(".tariff__card");
        const priceEl = wrapper.querySelector(
            ".tariff-card__item-value--price"
        );
        const ipPriceEl = wrapper.querySelector(
            ".tariff-card__item-value--ip-price"
        );
        const ports = ipPriceEl.dataset.ports;

        instance.passedElement.element.addEventListener("change", () => {
            const selectedValue = instance.getValue(true);

            console.log(selectedValue);

            if (selectedValue) {
                let price = parseFloat(priceEl.dataset.price);
                let quantity = parseInt(selectedValue);
                let total = price * quantity;

                priceEl.textContent =
                    "$" + (total % 1 === 0 ? total : total.toFixed(2));

                ipPriceEl.textContent =
                    "$" + (price * quantity) / parseInt(ports);
            }
        });
    }
});
